* {
  color: black;
  font-family: 'Anton', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #7D8F98
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem #eeeeee, 0 0 0 0.25rem #258cfb;
}

html {
  position: relative;
  min-height: 100%;
}

/* body {
  margin-bottom: 60px;
  color: black;
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  font-style: italic;
} */

.custom-header-color {
  background: hsla(223, 59%, 13%, 1);
  background: radial-gradient(circle, hsla(223, 59%, 13%, 1) 0%, hsla(219, 57%, 14%, 1) 60%);
  background: -moz-radial-gradient(circle, hsla(223, 59%, 13%, 1) 0%, hsla(219, 57%, 14%, 1) 60%);
  background: -webkit-radial-gradient(circle, hsla(223, 59%, 13%, 1) 0%, hsla(219, 57%, 14%, 1) 60%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0D1833", endColorstr="#0F1D37", GradientType=1);
}

.custom-class {
  background-color: #7D8F98 !important;
}

/* .h2text {
  color: black;
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  font-style: italic;
} */

.secondStatement {
  font-family: sans-serif;
}

.h1text {
  letter-spacing: -2px;
}

.collections {
  color: black;
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  font-style: italic;
}

.index-btn {
  background-color: #0C2131;
  color: #eeeeee;
  width: 10rem;
  margin: 0.7rem;
}

.index-btn:hover {
  background-color: #1d547d;
  color: #eeeeee;
}

.qty-btn {
  background-color: #0C2131;
  color: #eeeeee;
  width: 3rem;
  margin: 0.7rem;
}

.qty-btn:hover {
  background-color: #1d547d;
  color: #eeeeee;
}

.add-btn {
  background-color: #0C2131;
  color: #eeeeee;
  margin: 0.7rem;
}

.custome-card-header{
  background-color: #325269;
  color: #eeeeee;
}

.add-btn:hover {
  background-color: #1d547d;
  color: #eeeeee;
}

.footer-text {
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  color: #F9EF23;
}

.custom-bg {
  background-color: #7D8F98;
}

.bi {
  color: #eeeeee;
}

/* General styles for breadcrumb items */
.breadcrumb-item {
  position: relative;
  /* Set position to relative for the parent */
  background-color: #0C2131;
  padding: 0 5px;
}

/* Styles for links within breadcrumb items */
.breadcrumb-item a {
  text-decoration: none;
  color: #eeeeee;
}

/* Styles for the separator (after each link) */
.breadcrumb-item a::after {
  content: '';
  /* Remove the content of the pseudo-element */
  position: absolute;
  /* Position the pseudo-element */
  top: 50%;
  /* Align the pseudo-element vertically */
  left: calc(100% + 5px);
  /* Position the pseudo-element to the right of the link */
  border-top: 5px solid transparent;
  /* Create a transparent top border */
  border-bottom: 5px solid transparent;
  /* Create a transparent bottom border */
  border-left: 5px solid #7D8F98;
  /* Create a solid left border */
  transform: translateY(-50%);
  /* Adjust vertical position */
  z-index: 1;
}

/* Styles for the active (last) breadcrumb item */
.breadcrumb-item.active {
  color: #eeeeee;
  margin-right: 0.5rem;
  /* Adjust margin with a single value */
  padding-right: 1%;
  /* Remove padding on the right */
}

/* Adjust the top value to accommodate your navbar height */
.sticky-top {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 56px; /* Adjust based on your navbar height */
  z-index: 1000; /* Ensure it's above other content */
}

.rounded-1{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.index-gallery{
  height: 300px;
}
